/* Background override */
.intro {
    background: #7EC0EE;
    background: -webkit-linear-gradient(top, #7EC0EE 0%, #fff 100%);
    background: -linear-gradient(top, #7EC0EE 0%, #fff 100%);
    background: -moz-linear-gradient(top, #7EC0EE 0%, #fff 100%);
}

.grass {
    position: absolute;
    overflow: hidden;
}


/* Cloud Animation */
#clouds {
    position: absolute;
    overflow: hidden;    
    height: 100%;
    width: 100%;
    padding-top: 130px;
}

/* Time to finalise the cloud shape */
.cloud {
    width: 100px; height: 30px;
    background: #fff;
    
    border-radius: 100px;
    -moz-border-radius: 100px;
    -webkit-border-radius: 100px;
    position: relative; 
}

.cloud:before, .cloud:after {
    content: '';
    position: absolute; 
    background: #fff;
    width: 50px; height: 40px;
    position: absolute; top: -15px; left: 10px;
    
    border-radius: 50px;
    -moz-border-radius: 50px;
    -webkit-border-radius: 50px;
    
    -webkit-transform: rotate(30deg);
    transform: rotate(30deg);
    -moz-transform: rotate(30deg);
}

.cloud:after {
    width: 60px; height: 60px;
    top: -26px; left: auto; right: 8px;
}


@media #{$desktop} {
    /* Time to animate */
    .x1 {
        -webkit-animation: moveclouds 15s linear infinite;
        -moz-animation: moveclouds 15s linear infinite;
        -o-animation: moveclouds 15s linear infinite;
    }

    /* variable speed, opacity, and position of clouds for realistic effect */
    .x2 {
        left: 100px;
        
        -webkit-transform: scale(0.6);
        -moz-transform: scale(0.6);
        transform: scale(0.6);
        opacity: 0.6; /*opacity proportional to the size*/
        
        /*Speed will also be proportional to the size and opacity*/
        /*More the speed. Less the time in 's' = seconds*/
        -webkit-animation: moveclouds 25s linear infinite;
        -moz-animation: moveclouds 25s linear infinite;
        -o-animation: moveclouds 25s linear infinite;
    }

    .x3 {
        left: -125px; top: -100px;
        
        -webkit-transform: scale(0.8);
        -moz-transform: scale(0.8);
        transform: scale(0.8);
        opacity: 0.8; /*opacity proportional to the size*/
        
        -webkit-animation: moveclouds 20s linear infinite;
        -moz-animation: moveclouds 20s linear infinite;
        -o-animation: moveclouds 20s linear infinite;
    }

    .x4 {
        left: 235px; top: -125px;
        
        -webkit-transform: scale(0.75);
        -moz-transform: scale(0.75);
        transform: scale(0.75);
        opacity: 0.75; /*opacity proportional to the size*/
        
        -webkit-animation: moveclouds 18s linear infinite;
        -moz-animation: moveclouds 18s linear infinite;
        -o-animation: moveclouds 18s linear infinite;
    }

    .x5 {
        left: -75px; top: -75px;
        
        -webkit-transform: scale(0.8);
        -moz-transform: scale(0.8);
        transform: scale(0.8);
        opacity: 0.8; /*opacity proportional to the size*/
        
        -webkit-animation: moveclouds 20s linear infinite;
        -moz-animation: moveclouds 20s linear infinite;
        -o-animation: moveclouds 20s linear infinite;
    }

    @-webkit-keyframes moveclouds {
        0% {margin-left: 1000px;}
        100% {margin-left: -1000px;}
    }
    @-moz-keyframes moveclouds {
        0% {margin-left: 1000px;}
        100% {margin-left: -1000px;}
    }
    @-o-keyframes moveclouds {
        0% {margin-left: 1000px;}
        100% {margin-left: -1000px;}
    }
}

@media #{$tablet} {
    // static version
    .x1 {
        left: 20px;
    }

    .x2 {
        left: 150px;
        -webkit-transform: scale(0.6);
        -moz-transform: scale(0.6);
        transform: scale(0.6);
        opacity: 0.6;
    }

    .x3 {
        left: 275px; top: -100px;
        -webkit-transform: scale(0.8);
        -moz-transform: scale(0.8);
        transform: scale(0.8);
        opacity: 0.8;
    }

    .x4 {
        left: 440px; top: -125px;
        -webkit-transform: scale(0.75);
        -moz-transform: scale(0.75);
        transform: scale(0.75);
        opacity: 0.75;
    }

    .x5 {
        left: 600px; top: -75px;
        -webkit-transform: scale(0.8);
        -moz-transform: scale(0.8);
        transform: scale(0.8);
        opacity: 0.8;
    }
}