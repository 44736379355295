// Imports
@import 'clouds';
@import 'controls';

// Mixins
.base {
    padding-top: 100px;
    @media #{$desktop} {
        padding-top: 125px;
    }
}

.top-buffer { 
    padding-top: 40px; 
}

.intro ul.social-buttons li a {
    background-color: $primary-color;

    &:hover,
    &:active {
        color: $primary-color;
        background-color: darken($social-buttons-color, 10%);
    }
}

@media #{$desktop} {
    #page-top .navbar .container { 
        width: 100%; 
    }
}

.timeline-body a {
    color: #cfcfcf;
}

footer {
    padding: 10px 0 !important;
}

#skills {

    .item {
        display: inline-block;
        margin-left: 40px;
        margin-right: 40px;
        margin-bottom: 30px;

        .icon {
            color: $primary-color;
            border-color: #8cbaf7;
            background-color: white;

            width: 100px;
            height: 100px;
            line-height: 100px;
            text-align: center;
            font-size: 30px;
            border-style: solid;
            border-width: 1px;
            border-radius: 50%;
            display: block;
            margin-bottom: 24px;
        }

        span {
            cursor: pointer;
            -webkit-transition: all 0.5s ease-in-out;
            -moz-transition: all 0.5s ease-in-out;
            -ms-transition: all 0.5s ease-in-out;
            -o-transition: all 0.5s ease-in-out;
            transition: all 0.5s ease-in-out;
        }
    }

    .item:hover {
        .icon {
            color: $secondary-color;
            border-color:#38485e;
        }
    }
}